import { Checkout } from 'redux/actionTypes';
import { API, callAPI } from 'utils';

export const fetchCheckoutConfig = () => {
  return callAPI<any>({
    types: [
      Checkout.RETRIEVE_CHECKOUT_CONFIG_REQUEST,
      Checkout.RETRIEVE_CHECKOUT_CONFIG_SUCCESS,
      Checkout.RETRIEVE_CHECKOUT_CONFIG_FAILURE,
    ],
    endpoint: API.urls.checkoutConfig,
    method: 'GET',
    meta: { toast: false },
  });
};
