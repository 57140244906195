import { Flex, Link as ExternalLink, Text } from '@workshop/ui';
import { Banner } from 'components';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const confusedTomato = require('../../../assets/crazy_gherkin@3x.png');
const playStore = require('../../../assets/play_store.png');
const appStore = require('../../../assets/app_store.png');
const logoLarge = require('../../../assets/logo_large.png');
const sidekickqrcode = require('../../../assets/sidekick_qr_code.png');

const facebook = require('../../../assets/icons/facebook.png');
const instagram = require('../../../assets/icons/instagram.png');
const twitter = require('../../../assets/icons/twitter.png');
const youtube = require('../../../assets/icons/youtube.png');
const tiktok = require('../../../assets/icons/tiktok.png');

const links1 = [
  { id: '1', label: 'About Us', url: 'https://sortedfood.com/about' },
  { id: '2', label: 'Join the Team', url: 'https://sortedfood.com/careers/' },
  { id: '3', label: 'Partners', url: 'https://sortedfood.com/partnerships/' },
  { id: '4', label: 'Blog', url: 'https://sortedfood.com/blog' },
  { id: '5', label: 'Newsletter', url: 'https://www.getdrip.com/forms/174803132/submissions/new' },
];

const links2 = [
  { id: '1', label: 'Food ID', url: 'https://sortedfood.com/id' },
  { id: '2', label: 'Games', url: 'https://sortedfood.com/games' },
  { id: '3', label: 'Support Center', url: 'https://sortedclub.zendesk.com/' },
  { id: '4', label: 'Cookies', url: 'https://sortedfood.com/cookies/' },
  { id: '5', label: 'Terms', url: '' },
  { id: '6', label: 'Privacy Policy', url: 'https://sortedfood.com/privacy-policy/' },
];

const icons = [
  { id: '1', icon: instagram, url: 'https://instagram.com/sortedfood' },
  { id: '2', icon: twitter, url: 'https://twitter.com/sortedfood' },
  { id: '3', icon: facebook, url: 'https://facebook.com/sortedfood' },
  { id: '4', icon: tiktok, url: 'https://www.tiktok.com/@sortedfoodofficial' },
  { id: '5', icon: youtube, url: 'https://youtube.com/sortedfood' },
];

const DownloadSidekickScreen: React.FC<{}> = () => {
  return (
    <>
      <Flex display={['none', 'none', 'flex']}>
        <Banner displayExternalLink={false} />
      </Flex>
      <Flex
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        pt={['4', '4', '10']}
        backgroundColor="primary"
        pb="32"
      >
        <Flex px="4">
          <Flex
            flexDirection="column"
            mt={['8', '8', '2']}
            justifyContent="center"
            alignItems="center"
          >
            <Text
              fontFamily="greed"
              fontWeight={500}
              fontSize="22px"
              lineHeight="22px"
              textAlign="center"
              style={{ color: '#5d5725' }}
              mb="10"
            >
              CANCEL YOUR BORING DINNERS
            </Text>
            <Flex flexDir={['column', 'row', 'row']} mb="8">
              <Text
                fontFamily="greed"
                fontWeight={400}
                fontSize="65px"
                lineHeight="22px"
                textAlign="center"
                mr="2"
                mb={['10', '0', '0']}
              >
                Download
              </Text>
              <Text
                fontFamily="lemonado"
                fontWeight={400}
                fontSize="65px"
                lineHeight="22px"
                textAlign="center"
              >
                Sidekick
              </Text>
            </Flex>
            <Text
              fontFamily="greed"
              fontWeight={400}
              fontSize="24px"
              lineHeight="22px"
              textAlign="center"
            >
              Scan this QR code with your phone to download the Sidekick app.
            </Text>
          </Flex>
        </Flex>
        <Flex
          width={200}
          height={200}
          maxW="70vw"
          mt="12"
          mb="12"
          backgroundImage={sidekickqrcode}
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          backgroundPosition="center"
          borderRadius="24px"
          style={{ borderColor: '#c9bb3f' }}
          borderWidth="8px"
        />
        <Flex flexDir={['column', 'column', 'row']}>
          <ExternalLink href="https://onelink.sorted.club/vWe9/webgen" target="_blank">
            <Flex
              cursor="pointer"
              width={200}
              height={50}
              backgroundImage={appStore}
              backgroundRepeat="no-repeat"
              backgroundSize="contain"
              backgroundPosition="center"
              mb={['4', '4', '0']}
            />
          </ExternalLink>
          <ExternalLink href="https://onelink.sorted.club/vWe9/webgen" target="_blank">
            <Flex
              cursor="pointer"
              width={200}
              height={50}
              backgroundImage={playStore}
              backgroundRepeat="no-repeat"
              backgroundSize="contain"
              backgroundPosition="center"
            />
          </ExternalLink>
        </Flex>
      </Flex>
      {/* FOOTER */}
      <Flex
        px={['8', '8', '8', '8', '32']}
        width="100vw"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          width="100%"
          justifyContent="center"
          alignItems={['flex-start', 'flex-start', 'center']}
          flexDirection={['column', 'column', 'row']}
          height={['auto', 'auto', 300]}
          pt={['16', '16', '0']}
        >
          <Flex flexDirection="column" justifyContent="flex-start" flex={1}>
            <ExternalLink
              href="https://sortedfood.com/"
              target="_blank"
              _hover={{ textDecoration: 'none' }}
            >
              <Flex
                backgroundImage={logoLarge}
                height="85px"
                width="300px"
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
              />
            </ExternalLink>
            <Flex flexDir="row" mt="8">
              {icons.map(({ icon, url, id }, idx) => {
                return (
                  <ExternalLink
                    key={`footer-link-${id}`}
                    href={url}
                    target="_blank"
                    _hover={{ textDecoration: 'none' }}
                  >
                    <Flex
                      key={`footer-icon-${idx}`}
                      backgroundImage={icon}
                      width="30px"
                      height="30px"
                      backgroundSize="contain"
                      mr="4"
                    />
                  </ExternalLink>
                );
              })}
            </Flex>
            <Text fontFamily="Greed" mt="8" fontSize="14px" fontWeight={200}>
              Copyright © 2023 Sorted Ventures Ltd. All rights reserved.
            </Text>
          </Flex>
          <Flex flexDirection={['column', 'column', 'row']} justifyContent="flex-start" flex={1}>
            <Flex flexDir="column" flex={1}>
              {links1.map(({ label, url, id }, idx) => {
                return (
                  <ExternalLink
                    key={`footer-link-${id}`}
                    href={url}
                    target="_blank"
                    _hover={{ textDecoration: 'none' }}
                  >
                    <Text fontFamily="Greed" mt="4" fontSize="14px" fontWeight={200}>
                      {label}
                    </Text>
                  </ExternalLink>
                );
              })}
            </Flex>
            <Flex flexDir="column" flex={1}>
              {links2.map(({ label, url, id }, idx) => {
                if (label === 'Terms') {
                  return (
                    <Flex flexDir="row" key={`footer-link-${id}`}>
                      <Text fontFamily="Greed" mt="4" fontSize="14px" fontWeight={200} mr="1">
                        {label} -
                      </Text>
                      <ExternalLink
                        href="https://sortedfood.com/website-terms/"
                        target="_blank"
                        _hover={{ textDecoration: 'none' }}
                      >
                        <Text fontFamily="Greed" mt="4" fontSize="14px" fontWeight={200}>
                          Website
                        </Text>
                      </ExternalLink>
                      <Text fontFamily="Greed" mt="4" fontSize="14px" fontWeight={200} mx="1">
                        |
                      </Text>
                      <ExternalLink
                        href="https://sortedfood.com/membership-terms/"
                        target="_blank"
                        _hover={{ textDecoration: 'none' }}
                      >
                        <Text fontFamily="Greed" mt="4" fontSize="14px" fontWeight={200}>
                          Subscriptions
                        </Text>
                      </ExternalLink>
                    </Flex>
                  );
                }

                return (
                  <ExternalLink
                    key={`footer-link-${id}`}
                    href={url}
                    target="_blank"
                    _hover={{ textDecoration: 'none' }}
                  >
                    <Text fontFamily="Greed" mt="4" fontSize="14px" fontWeight={200}>
                      {label}
                    </Text>
                  </ExternalLink>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default DownloadSidekickScreen;
