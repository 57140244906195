import { signInWithEmailAndPassword, UserCredential } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import navRoutes from 'navigation/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { setUserId, logEvent } from '@amplitude/analytics-browser';
import { Helmet } from 'react-helmet';

import { Flex, Heading, Text, useToast } from '@workshop/ui';

import { FirebaseError } from 'firebase/app';
import { useLocation } from 'react-router-dom';
import { authActions } from 'redux/actions';
import { SignupForm } from 'screens/Signup/SignupForm';
import { GlobalState, SignInSuccess, SignupData, UserInfo } from 'types';
import { auth, getParamFromUrl, getPortalErrorMessage } from 'utils';

const logoSmall = require('../../../assets/logo_small.png');
const onionImg = require('../../../assets/onion_mat@3x.png');
const logoGopuffHoriz = require('../../../assets/logo_gopuff_horiz.png');
const logoGopuffVert = require('../../../assets/logo_gopuff_vert.png');

const signInSuccess = (userInfo: UserInfo): SignInSuccess => ({
  type: 'SIGN_IN_SUCCESS',
  payload: { ...userInfo },
});

const SignupScreen: React.FC<{ history: any }> = ({ history }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [signUpCompleted, setSignUpCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast({
    position: 'top',
    duration: 3000,
    containerStyle: { backgroundColor: 'statusError' },
  });

  const location = useLocation();
  const priceId = getParamFromUrl(location, 'price_id');
  const partner = getParamFromUrl(location, 'partner');
  const couponCode = getParamFromUrl(location, 'coupon_code');

  useEffect(() => {
    if (!couponCode || !priceId) {
      history.push(navRoutes.public.download.path());
    }
  }, [couponCode, priceId]);

  const authToken = useSelector((state: GlobalState) => state.auth.authToken);

  useEffect(() => {
    if (!authToken || !signUpCompleted) return;

    const postSignupRedirect = async () => {
      const user = await dispatch(authActions.retrieveUser());

      // @ts-ignore
      const userId: number = user?.payload?.id;
      // @ts-ignore
      const uid: string = user?.payload?.uid;
      // @ts-ignore
      const email: string = user?.payload?.email;

      if (userId) {
        setUserId(userId.toString());
        await logEvent('web_app_signup').promise;
      }

      if (uid) {
        _cio.identify({
          // Required attributes
          id: uid,
          email: email,
        });
        _cio.track('web_app_signup');
      }

      // If a priceId is present in the location params, call the create-checkout endpoint,
      // otherwise fall back to the create-portal endpoint.
      const nextAction =
        priceId !== null
          ? authActions.createCheckoutSession({ priceId, ...(couponCode ? { couponCode } : {}) })
          : authActions.createPortalSession();

      const checkoutRes = await dispatch(nextAction);

      // @ts-ignore
      if (checkoutRes?.payload?.url) {
        // @ts-ignore
        window.location.href = checkoutRes?.payload?.url;
        return;
      }

      // @ts-ignore
      setErrorMessage(getPortalErrorMessage(checkoutRes?.payload?.error?.message));
      setIsLoading(false);
    };

    postSignupRedirect();
  }, [authToken, signUpCompleted]);

  const onSubmit = async ({ email, name, password }: SignupData) => {
    setIsLoading(true);
    // Call our own API to create the user with name+email+password
    const signupRes = await dispatch(
      authActions.signupRequest({
        email,
        name,
        password,
      }),
    );

    // @ts-ignore
    if (signupRes?.error) {
      toast({
        title: 'Sign-up failed. Please try again later.',
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    // Attempt to sign in via Firebase after successful signup
    // (i.e, authenticate and retrieve the auth token)
    let userCredential: UserCredential | undefined;
    try {
      userCredential = await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      // TODO : add error handling
      const error = err as FirebaseError;
      if (error.code === 'auth/wrong-password') {
        toast({
          title: 'Authentication failed during sign-up.',
          status: 'error',
          duration: 1000,
          isClosable: true,
        });
        setIsLoading(false);
      }
      return;
    }

    if (!userCredential) {
      toast({
        title: 'Failed to retrieve credentials. Please reresh the page and try again.',
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    const token = await userCredential.user.getIdTokenResult();

    // Send the token to the redux store
    dispatch(
      signInSuccess({
        token: token.token,
        expirationTime: token.expirationTime,
        displayName: userCredential.user.displayName,
      }),
    );

    setSignUpCompleted(true);
  };

  return (
    <Flex flexDir={['column', 'column', 'row']}>
      <Helmet>
        <title>Sidekick - Sign up</title>
      </Helmet>
      <Flex
        display={['none', 'none', 'flex']}
        backgroundImage={logoSmall}
        width="28px"
        height="28px"
        position="absolute"
        left={75}
        top={50}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      />
      <Flex
        flex="0 0 40%"
        backgroundColor="primary"
        flexDirection="column"
        alignItems="flex-start"
        minHeight={['0', '0', '100vh']}
        pt={['10', '10', '20']}
        pb="10"
        pl="20"
        pr="10"
      >
        <Flex pt={['0', '0', '20']} width="100%" />
        {partner !== 'gopuff' && (
          <Text textAlign="left" fontWeight="bold" fontSize={['lg', 'lg', 'xl']}>
            Get started with Sidekick
          </Text>
        )}
        <Flex
          display={partner === 'gopuff' ? 'block' : ['none', 'none', 'block']}
          width={250}
          height={[50, 150, 250]}
          mt="8"
          backgroundImage={
            partner === 'gopuff' ? [logoGopuffHoriz, logoGopuffVert, logoGopuffVert] : onionImg
          }
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          backgroundPosition="center"
        />
        {partner === 'gopuff' && (
          <Text textAlign="left" fontWeight="bold" fontSize={['lg', 'lg', 'xl']}>
            Sign Up & Redeem Your Coupon Prize Now!
          </Text>
        )}
      </Flex>
      {errorMessage ? (
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          minHeight="100vh"
          backgroundColor="white"
          flex={1}
          p="20"
          pt={['10', '10', '20']}
        >
          <Flex pt={['0', '0', '20']} width="100%" />
          <Heading fontSize={['xl', 'xl', 'xl']} mb="8">
            {errorMessage}
          </Heading>
          <Flex flex={1} />
        </Flex>
      ) : (
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          minHeight="100vh"
          backgroundColor="white"
          flex={1}
          p="20"
          pt={['10', '10', '20']}
        >
          <Flex pt={['0', '0', '20']} width="100%" />
          <Heading fontSize={['2xl', '2xl', '4xl']} mb="8">
            Sign up with email
          </Heading>
          <SignupForm onSubmit={onSubmit} isLoading={isLoading} />
        </Flex>
      )}
    </Flex>
  );
};

export default SignupScreen;
