import navRoutes from 'navigation/Routes';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';

import { SignupData } from 'types';

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link as ExternalLink,
  Text,
} from '@workshop/ui';
import { getParamFromUrl } from 'utils';

type SignupFormData = Omit<SignupData, 'marketingConsent'>;

interface SignupFormProps {
  onSubmit: (data: SignupData) => Promise<void>;
  isLoading?: boolean;
}

const SignupForm: React.FC<SignupFormProps> = ({ onSubmit, isLoading = false }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const location = useLocation();
  const priceId = getParamFromUrl(location, 'price_id');
  const partner = getParamFromUrl(location, 'partner');
  const couponCode = getParamFromUrl(location, 'coupon_code');

  let loginUrl = `${navRoutes.public.login.path()}`;
  if (priceId) {
    loginUrl = loginUrl.concat(`?price_id=${priceId}`);
    
    if(partner) {
      loginUrl = loginUrl.concat(`&partner=${partner}`);
    }
    if(couponCode) {
      loginUrl = loginUrl.concat(`&coupon_code=${couponCode}`);
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<SignupFormData>({
    defaultValues: { email: '', name: '', password: '' },
  });

  const onSubmitData = handleSubmit(async ({ email, name, password }) => {
    setIsSubmitting(true);
    await onSubmit({
      email,
      name,
      password,
    });
    setIsSubmitting(false);
  });

  const touchedFields = watch(['email', 'name', 'password']).filter(a => a.length > 1);

  return (
    <Flex flex={1} width="100%" maxW={500}>
      <Flex flexDirection="column" flex={1}>
        <Flex flexDirection="column" mb="4">
          <FormControl isInvalid={Boolean(errors.email)}>
            <FormLabel fontSize="sm" style={{ color: '#3C4257' }}>
              Email
            </FormLabel>
            <Input
              {...register('email', {
                required: true,
                validate: value => isEmail(value),
              })}
              id="email"
              name="email"
              placeholder="Email"
              type="email"
            />
            <FormErrorMessage color="statusError">
              Please enter a valid email address
            </FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex flexDirection="column" mb="4">
          <FormControl isInvalid={Boolean(errors.name)}>
            <FormLabel fontSize="sm" style={{ color: '#3C4257' }}>
              Full Name
            </FormLabel>
            <Input
              {...register('name', {
                required: {
                  value: true,
                  message: 'Please enter your full name',
                },
              })}
              id="name"
              name="name"
              placeholder="Full Name"
              type="name"
            />
            <FormErrorMessage color="statusError">{errors.name?.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex flexDirection="column" mb="4">
          <FormControl isInvalid={Boolean(errors.password)}>
            <FormLabel fontSize="sm" style={{ color: '#3C4257' }}>
              Password
            </FormLabel>
            <Input
              {...register('password', {
                required: {
                  value: true,
                  message: 'Please enter your password',
                },
                minLength: {
                  value: 8,
                  message: 'Your password must be at least 8 characters',
                },
              })}
              id="password"
              name="password"
              placeholder="Password"
              type="password"
            />
            <FormErrorMessage color="statusError">{errors.password?.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Button
          isDisabled={isLoading || isSubmitting || Object.values(touchedFields).length < 3}
          isLoading={isLoading || isSubmitting}
          mt="2"
          onClick={() => {
            onSubmitData();
          }}
          variant="solid"
          label="Sign Up"
        />

        <Flex mt="4" py={4} width="100%" flexDir="column">
          <Text flex={1} mb="8" fontSize="sm">
            By continuing, you are indicating that you accept our{' '}
            <ExternalLink href="https://sortedfood.com/website-terms/" target="_blank">
              <Text as="span" fontWeight="bold" color="secondary">
                Terms of Service
              </Text>
            </ExternalLink>{' '}
            and{' '}
            <ExternalLink href="https://sortedfood.com/privacy-policy/" target="_blank">
              <Text as="span" fontWeight="bold" color="secondary">
                Privacy Policy.
              </Text>
            </ExternalLink>
          </Text>
          <Text flex={1} fontSize="sm">
            Already have an account?{' '}
            <Link to={loginUrl}>
              <Text as="span" fontWeight="bold" color="secondary">
                Sign In
              </Text>
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SignupForm;
