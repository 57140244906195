import { CustomIcon, Flex, Link as ExternalLink, Text } from '@workshop/ui';
import React from 'react';
import { Link } from 'react-router-dom';

const logoLarge = require('../../assets/logo_large.png');

const bannerLinks = [
  { label: 'SIDEKICK' },
  { label: 'VIDEOS', href: 'https://sortedfood.com/videos' },
  { label: 'STORE', href: 'https://store.sortedfood.com/' },
  { label: 'ID', href: 'https://sortedfood.com/id' },
  { label: 'FOOD TEAM', href: 'https://sortedfood.substack.com/' },
  { label: 'GET THE APP', href: 'https://onelink.sorted.club/vWe9/webgen' },
];

const Banner: React.FC<{ isRecipe?: boolean; displayExternalLink?: boolean }> = ({
  isRecipe = false,
  displayExternalLink = true,
}) => {
  return (
    <Flex width="100vw" justifyContent="center" alignItems="center" flexDir="column">
      <Flex
        display={['none', 'flex', 'flex']}
        position="relative"
        justifyContent="center"
        alignItems="center"
        style={{
          paddingTop: '22px',
          paddingBottom: '22px',
        }}
        width="100%"
      >
        <ExternalLink
          href="https://sortedfood.com/"
          target="_blank"
          _hover={{ textDecoration: 'none' }}
        >
          <Flex
            backgroundImage={logoLarge}
            position="absolute"
            left="30px"
            width="150px"
            top={0}
            bottom={0}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
          />
        </ExternalLink>
        {bannerLinks.map(({ label, href }, idx) => {
          const linkWrapper =
            idx === 0
              ? { Component: Link, props: { to: '/' } }
              : {
                  Component: ExternalLink,
                  props: { href, target: '_blank', _hover: { textDecoration: 'none' } },
                };
          const { Component, props: wrapperProps } = linkWrapper;
          return (
            // @ts-ignore
            <Component {...wrapperProps} key={idx}>
              <Flex
                {...(idx + 1 === bannerLinks.length
                  ? {
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'primary',
                      cursor: 'pointer',
                      style: { margin: '0 10px', padding: '5px 15px', borderRadius: '20px' },
                      _hover: { opacity: 0.7 },
                    }
                  : {})}
              >
                <Text
                  fontWeight="600"
                  fontFamily="Greed"
                  fontSize="12px"
                  letterSpacing="0.6px"
                  lineHeight="26px"
                  cursor="pointer"
                  _hover={{ opacity: 0.7 }}
                  style={
                    idx + 1 === bannerLinks.length
                      ? {}
                      : { margin: '0 10px', color: idx === 0 ? '#45C181' : 'black' }
                  }
                >
                  {label}
                </Text>
              </Flex>
            </Component>
          );
        })}
      </Flex>
      {displayExternalLink && (
        <ExternalLink
          href="https://onelink.sorted.club/vWe9/webgen"
          target="_blank"
          style={{ width: '100%', textDecoration: 'none' }}
        >
          <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
            backgroundColor="primary"
            py="2"
            cursor="pointer"
          >
            <CustomIcon name="error" size="md" mr="2" />
            <Text
              fontWeight="600"
              fontFamily="Greed"
              fontSize="20px"
              letterSpacing="0.6px"
              lineHeight="26px"
            >
              {`Get the app to gain access to the full ${isRecipe ? 'recipe' : 'recipe pack'}`}
            </Text>
          </Flex>
        </ExternalLink>
      )}
    </Flex>
  );
};

export default Banner;
