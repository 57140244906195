import {
  Button,
  Flex,
  Heading,
  Link as ExternalLink,
  Paragraph,
  Spinner,
  Text,
} from '@workshop/ui';
import navRoutes from 'navigation/Routes';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { subscriptionActions } from 'redux/actions';
import { getParamFromUrl } from 'utils';

const logoSmall = require('../../../assets/logo_small.png');
const lemonImg = require('../../../assets/lemon@3x.png');
const charactersGroup = require('../../../assets/characters_group.png');

// type GeoLocation = {
//   city: string;
//   continentCode: string;
//   countryCode: string;
//   lat: number;
//   lon: number;
//   proxy: false;
//   query: string;
//   region: string;
//   status: string;
//   timezone: string;
// };

// async function getGeoLocation() {
//   try {
//     let response = await fetch(
//       'https://pro.ip-api.com/json/?key=5lVrlbfIKvZ3Y2L&fields=status,continentCode,countryCode,region,city,lat,lon,timezone,proxy,query',
//     );
//     let responseJson = await response.json();
//     return responseJson as GeoLocation;
//   } catch (error) {
//     console.error(error);
//   }
// }

const PriceButton: React.FC<{
  label: string;
  billing: 'monthly' | 'yearly';
  priceLabel: string;
  isLoading: boolean;
  priceCode: string | null;
  isSelected: boolean;
  onClick: () => void;
}> = ({ label, billing, priceLabel, isLoading, priceCode, isSelected, onClick }) => {
  return (
    <Flex
      p="4"
      flex={1}
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
      borderWidth={4}
      borderRadius="lg"
      minH={[100, 300, 300]}
      cursor="pointer"
      borderColor={isSelected ? 'primary' : 'transparent'}
      boxShadow={isSelected ? 'lg' : 'inset 0px 0px 0px 2px #e6e6e6'}
      onClick={onClick}
      position="relative"
      width="100%"
      maxW={250}
    >
      {isLoading && (
        <>
          <Flex />
          <Spinner />
          <Flex />
        </>
      )}
      {!isLoading && (
        <>
          {billing === 'yearly' && (
            <Flex
              position="absolute"
              right={4}
              top={-30}
              backgroundColor="primary"
              h={30}
              px="2"
              borderTopRadius="md"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontFamily="lemonado" textTransform="uppercase">
                best value
              </Text>
            </Flex>
          )}
          <Heading fontFamily="lemonado" textTransform="uppercase">
            {billing === 'monthly' ? 'Monthly' : 'Yearly'}
          </Heading>
          <Flex flexDir="column" justifyContent="center" alignItems="center">
            <Flex flexDir="row" justifyContent="center" alignItems="flex-end">
              <Heading>{priceLabel}</Heading>
              <Paragraph fontSize="xl" fontWeight="bold">
                /MO
              </Paragraph>
            </Flex>
            <Paragraph fontSize="sm" textTransform="capitalize">
              {label}
            </Paragraph>
          </Flex>
          <Flex
            backgroundColor={billing === 'monthly' ? 'transparent' : 'primary'}
            py="1"
            px="4"
            justifyContent="center"
            alignItems="center"
            borderRadius="lg"
            minH={8}
            minW={130}
            display={label === 'monthly' ? ['none', 'flex', 'flex'] : 'flex'}
          >
            {billing === 'yearly' && (
              <Text fontSize="sm" fontWeight="bold">
                Save 17%
              </Text>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

type Prices = {
  standard: {
    monthly: string;
    annual: string;
  };
  household: {
    monthly: string;
    annual: string;
  };
};

const CheckoutScreen: React.FC<{ history: any }> = ({ history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [prices, setPrices] = useState<Prices | null>();
  const [selectedPlan, setSelectedPlan] = useState<'standard' | 'household'>('standard');
  const [selectedPrice, setSelectedPrice] = useState<'monthly' | 'annual'>('annual');
  const dispatch = useDispatch();

  const location = useLocation();
  const couponCode = getParamFromUrl(location, 'coupon_code');
  const isAllowedToPurchase = Boolean(couponCode);

  const monthlyPrice = prices ? prices[selectedPlan].monthly : null;
  const annualPrice = prices ? prices[selectedPlan].annual : null;

  let loginUrl = `${navRoutes.public.login.path()}`;

  useEffect(() => {
    if (!couponCode) {
      history.push(navRoutes.public.download.path());
    }
  }, [couponCode]);

  useEffect(() => {
    const loadConfig = async () => {
      const res = await dispatch(subscriptionActions.fetchCheckoutConfig());
      setIsLoading(false);

      // @ts-ignore
      if (res.type !== 'RETRIEVE_CHECKOUT_CONFIG_SUCCESS') {
        return;
      }

      const {
        // @ts-ignore
        payload: { monthlyPrice, annualPrice, monthlyHouseholdPrice, annualHouseholdPrice },
      } = res;

      setPrices({
        standard: {
          monthly: monthlyPrice,
          annual: annualPrice,
        },
        household: {
          monthly: monthlyHouseholdPrice,
          annual: annualHouseholdPrice,
        },
      });
    };

    // const fetchConfig = async () => {
    // const data = await getGeoLocation();

    // // Only allow purchasing in the UK & EU (excluding Norway & Switzerland)
    // const shouldLoadConfig =
    //   data &&
    //   (data['countryCode'] === 'GB' ||
    //     (data['continentCode'] === 'EU' &&
    //       data['countryCode'] !== 'CH' &&
    //       data['countryCode'] !== 'NO'));

    // if (!shouldLoadConfig) {
    //   setIsAllowedToPurchase(false);
    //   setIsLoading(false);
    //   return;
    // }

    // await loadConfig();
    // };

    loadConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDir={['column', 'column', 'row']}>
      <Helmet>
        <title>Sidekick - Choose your plan</title>
      </Helmet>
      <Flex
        display={['none', 'none', 'flex']}
        backgroundImage={logoSmall}
        width="28px"
        height="28px"
        position="absolute"
        left={75}
        top={50}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      />
      <Flex
        flex="0 0 40%"
        backgroundColor="primary"
        flexDirection="column"
        alignItems="flex-start"
        minHeight={['0', '0', '100vh']}
        pt={['10', '10', '20']}
        pb="10"
        px="20"
      >
        <Flex pt={['0', '0', '20']} width="100%" />
        <Text textAlign="left" fontWeight="bold" fontSize={['lg', 'lg', 'xl']}>
          Choose your plan
        </Text>
        <Flex
          display={['none', 'none', 'block']}
          width={250}
          height={250}
          mt="8"
          backgroundImage={selectedPlan === 'standard' ? lemonImg : charactersGroup}
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          backgroundPosition="center"
        />
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent={['flex-start', 'flex-start', 'center']}
        minHeight="100vh"
        backgroundColor="white"
        flex={1}
        p="20"
        pt={['10', '10', '20']}
        maxW={750}
      >
        <Flex pt={['0', '0', '20']} width="100%" />
        <Heading fontSize={['2xl', '2xl', '4xl']} mb="2">
          {isAllowedToPurchase ? 'Select your billing plan' : 'Download the app'}
        </Heading>
        <Paragraph>
          {isAllowedToPurchase
            ? 'Choose a plan for after your 1-month free trial'
            : 'Download the app on your device and start cooking'}
        </Paragraph>

        <Flex flexDir="column" flex={1} width="100%">
          <Flex
            flexDir="column"
            flex={isAllowedToPurchase ? 1 : 0}
            width={isAllowedToPurchase ? ['100%', '85%', '100%', '85%'] : '100%'}
            mt={isAllowedToPurchase ? '8' : '0'}
          >
            {isAllowedToPurchase && (
              <Flex
                flexDir={['column-reverse', 'row', 'row']}
                justifyContent="flex-start"
                alignItems="center"
              >
                <PriceButton
                  billing="monthly"
                  label="/Per Month"
                  priceLabel={selectedPlan === 'standard' ? '£4.99' : '£7.99'}
                  isLoading={isLoading}
                  priceCode={monthlyPrice || ''}
                  isSelected={selectedPrice === 'monthly'}
                  onClick={() => setSelectedPrice('monthly')}
                />
                <Flex mx={['0', '2', '2']} my={['2', '0', '0']} />
                <PriceButton
                  billing="yearly"
                  label={`/ *£${selectedPlan === 'standard' ? '49' : '79'}.99 Billed Annually`}
                  priceLabel={selectedPlan === 'standard' ? '£4.16' : '£6.66'}
                  isLoading={isLoading}
                  priceCode={annualPrice || ''}
                  isSelected={selectedPrice === 'annual'}
                  onClick={() => setSelectedPrice('annual')}
                />
              </Flex>
            )}
            <Button
              variant="solid"
              colorScheme="blue"
              type="submit"
              textTransform="initial"
              mt={4}
              fontSize="md"
              isLoading={isLoading}
              onClick={() => {
                if (!isAllowedToPurchase) {
                  window.open('https://onelink.sorted.club/vWe9/webgen', '_blank');
                  return;
                }
                history.push(
                  `${navRoutes.public.signup.path()}?price_id=${
                    selectedPrice === 'monthly' ? monthlyPrice : annualPrice
                  }&coupon_code=${couponCode}`,
                );
              }}
              label={isAllowedToPurchase ? 'START MY FREE TRIAL' : 'DOWNLOAD THE APP'}
              w="100%"
            />
          </Flex>
          {isAllowedToPurchase && (
            <Flex flexDirection="column" flex={1}>
              <Flex mt="4" py={4} width="100%" flexDir="column">
                <Text flex={1} mb="8" fontSize="sm">
                  By continuing, you are indicating that you accept our{' '}
                  <ExternalLink href="https://sortedfood.com/website-terms/" target="_blank">
                    <Text as="span" fontWeight="bold" color="secondary">
                      Terms of Service
                    </Text>
                  </ExternalLink>{' '}
                  and{' '}
                  <ExternalLink href="https://sortedfood.com/privacy-policy/" target="_blank">
                    <Text as="span" fontWeight="bold" color="secondary">
                      Privacy Policy.
                    </Text>
                  </ExternalLink>
                </Text>
                <Text flex={1} fontSize="sm">
                  Already have an account?{' '}
                  <Link to={loginUrl}>
                    <Text as="span" fontWeight="bold" color="secondary">
                      Sign In
                    </Text>
                  </Link>
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CheckoutScreen;
